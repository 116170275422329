import { useState } from 'react';
import { MenuAlt3Icon, SearchIcon, UserCircleIcon } from '@heroicons/react/outline';
import { useNavigate } from 'react-router-dom';

const Header = ({ toggleSidebar }) => {
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/login'); // Corrected to use navigate directly
    };

    const [search, setSearch] = useState('');

    return (
        <header className="flex items-center justify-between bg-white shadow-md p-4">
            <div className="flex items-center">
                <button onClick={toggleSidebar} className="text-gray-500 focus:outline-none">
                    <MenuAlt3Icon className="h-8 w-8" />
                </button>
                <div className="ml-4 text-xl font-semibold text-gray-700">Admin Dashboard</div>
            </div>
            <div className="flex items-center">
                <div className="relative text-gray-600 focus-within:text-gray-400">
                    <SearchIcon className="absolute left-3 top-2.5 h-5 w-5" />
                    <input
                        type="search"
                        name="search"
                        className="py-2 text-sm text-white bg-gray-200 rounded-md pl-10 focus:outline-none focus:bg-white focus:text-gray-900"
                        placeholder="Search..."
                        autoComplete="off"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
                <button onClick={handleLogout}>Logout</button>
                <div className="ml-4 relative">
                    <button className="focus:outline-none">
                        <UserCircleIcon className="h-10 w-10 text-gray-600" />
                    </button>
                    {/* Dropdown could go here */}
                </div>
            </div>
        </header>
    );
};

export default Header;
