import React from 'react';
import Header from '../components/Header';
import Hero from '../components/Hero';
import Products from '../components/Products';
import Keynotes from '../components/Keynotes';
import Footer from '../components/Footer';

const Home = () => {
    return (
        <div>
            <Header />
            <Hero />
            <Keynotes />
            <Products />
            <Hero />
            <Footer />
        </div>
    );
};

export default Home;