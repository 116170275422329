import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../axiosConfig';

const AdminLogin = () => {
  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const navigate = useNavigate();

  const login = () => {
    axiosInstance.post('/login', credentials).then(response => {
      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
        navigate('/dashboard');
      } else {
        alert('Login failed');
      }
    }).catch(error => {
      console.error('Login error', error);
      alert('Login failed');
    });
  };

  return (
    <div className="flex items-center justify-center h-screen bg-blue-500">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <div className="text-2xl font-semibold text-center text-gray-800">Admin Login</div>
        <div className="mt-4">
          <input
            type="text"
            className="w-full p-2 border border-gray-300 rounded mt-2"
            placeholder="Username"
            value={credentials.username}  // Fixed
            onChange={(e) => setCredentials({ ...credentials, username: e.target.value })}  // Fixed
          />
          <input
            type="password"
            className="w-full p-2 border border-gray-300 rounded mt-4"
            placeholder="Password"
            value={credentials.password}
            onChange={(e) => setCredentials({ ...credentials, password: e.target.value })}
          />
          <button
            className="w-full bg-blue-500 text-white p-2 rounded mt-6"
            onClick={login}
          >
            Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
