import React from 'react';
import { FaInstagram, FaFacebook, FaYoutube, FaWhatsapp } from 'react-icons/fa';
import logo from '../images/whitelogo.png';

const Footer = () => {
    return (
        <footer className="bg-gray-800 text-white p-6">
            <div className="container mx-auto flex flex-col md:flex-row justify-between items-center">
                {/* Logo and Project Name */}
                <div className="flex items-center mb-4 md:mb-0">
                    <img src={logo} alt="AgroVet Estrela Logo" className="h-12 w-12 mr-2" />
                    <span className="text-2xl font-semibold">AgroVeterinária Estrela</span>
                </div>

                {/* Social Media Icons */}
                <div className="flex space-x-4">
                    <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" className="hover:text-blue-500 transition-colors">
                        <FaInstagram size={24} />
                    </a>
                    <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" className="hover:text-blue-500 transition-colors">
                        <FaFacebook size={24} />
                    </a>
                    <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer" className="hover:text-blue-500 transition-colors">
                        <FaYoutube size={24} />
                    </a>
                    <a href="https://wa.me/your-number" target="_blank" rel="noopener noreferrer" className="hover:text-blue-500 transition-colors">
                        <FaWhatsapp size={24} />
                    </a>
                </div>

                {/* Copyright Text */}
                <div className="mt-4 md:mt-0 text-center">
                    <p>&copy; 2024 AgroVeterinária Estrela. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
