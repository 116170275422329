export const products = [
  {
    "image": "https://www.royal-horse.com/wp-content/uploads/2023/11/mockup_royal-horse_original_30kg_230x300px_v2.png",
    "name": "Original (L-100)",
    "description": "Boa manutenção da condição corporal\nFonte de fibras\nSaúde do trato digestivo Linha Lazer Cavalos",
    "category": "Equinos",
    "url": "https://www.royal-horse.com/pt-br/alimentacao-cavalos/royal-horse-original-peletizado-com-banho-de-melaco/"
  , "inStock": "Em Estoque" },
  {
    "image": "https://www.royal-horse.com/wp-content/uploads/2023/10/royalhorse_embalagem_vita-40KG_mockup_230x300px_v2.png",
    "name": "Vita (L-150)",
    "description": "Garantindo o equilíbrio digestivo Linha Lazer Cavalos",
    "category": "Equinos",
    "url": "https://www.royal-horse.com/pt-br/alimentacao-cavalos/royal-horse-vita/"
  , "inStock": "Em Estoque" },
  {
    "image": "https://www.royal-horse.com/wp-content/uploads/2023/10/royalhorse_embalagem_-generica-sem_peso_mockup_230x300px_v2.png",
    "name": "Advance (L-180)",
    "description": "Boa manutenção da condição corporal Linha Lazer Cavalos",
    "category": "Equinos",
    "url": "https://www.royal-horse.com/pt-br/alimentacao-cavalos/royal-horse-advance/"
  , "inStock": "Em Estoque" },
  {
    "image": "https://www.royal-horse.com/wp-content/uploads/2023/09/mockup_royal-horse_passeio_40kg_230x300px_v2.png",
    "name": "Passeio (L-250)",
    "description": "Mantém a condição corporal Linha Lazer Cavalos",
    "category": "Equinos",
    "url": "https://www.royal-horse.com/pt-br/alimentacao-cavalos/royal-horse-passeio-multicomponente-banho-de-melaco/"
  , "inStock": "Em Estoque" },
  {
    "image": "https://www.royal-horse.com/wp-content/uploads/2022/03/SPORT.png",
    "name": "Sport S-150",
    "description": "Protege o sistema digestivo e apóia a atividade muscular Linha Sport cavalos",
    "category": "Equinos",
    "url": "https://www.royal-horse.com/pt-br/alimentacao-cavalos/sport-racao-multiparticula-milho-extrusado-aveia-cavalos-esporte/"
  , "inStock": "Em Estoque" },
  {
    "image": "https://www.royal-horse.com/wp-content/uploads/2022/04/SUPERFORM-2.png",
    "name": "Superform S-250",
    "description": "Protege o sistema digestivo e apóia a atividade muscular Linha Sport cavalos",
    "category": "Equinos",
    "url": "https://www.royal-horse.com/pt-br/alimentacao-cavalos/royal-horse-superform-racao-milho-extrusado-aveia-cavalos-esporte/"
  , "inStock": "Em Estoque" },
  {
    "image": "https://www.royal-horse.com/wp-content/uploads/2022/03/POTENCE.png",
    "name": "Potence S-280",
    "description": "Altíssimo aproveitamento, maior saúde do trato digestivo e auxilia na prevenção de gastrites Linha Sport cavalos",
    "category": "Equinos",
    "url": "https://www.royal-horse.com/pt-br/alimentacao-cavalos/potence-racao-extrusada-cavalos-esporte/"
  , "inStock": "Em Estoque" },
  {
    "image": "https://www.royal-horse.com/wp-content/uploads/2022/04/ABSOLUT-SPEED-1.png",
    "name": "Absolut Speed",
    "description": "Alimentação específica para esforços curtos e intensos Linha Competição Cavalos",
    "category": "Equinos",
    "url": "https://www.royal-horse.com/pt-br/alimentacao-cavalos/royal-horse-absolut-speed-racao-multiparticulas-milho-extrusado-cavalos-atletas/"
  , "inStock": "Em Estoque" },
  {
    "image": "https://www.royal-horse.com/wp-content/uploads/2022/04/PODIUM-1.png",
    "name": "Podium H-350",
    "description": "Melhoria do desempenho atlético Linha Competição Cavalos",
    "category": "Equinos",
    "url": "https://www.royal-horse.com/pt-br/alimentacao-cavalos/podium-multiparticulas-milho-extrusado-aveia-cavalos-atletas-esforco-intenso/"
  , "inStock": "Em Estoque" },
  {
    "image": "https://www.royal-horse.com/wp-content/uploads/2022/04/FERTILITE.png",
    "name": "Fertilité B-100",
    "description": "Reforça a imunidade e a fertilidade Linha Criação cavalos",
    "category": "Equinos",
    "url": "https://www.royal-horse.com/pt-br/alimentacao-cavalos/fertilite-peletizado-eguas-cria-potros/"
  , "inStock": "Em Estoque" },
  {
    "image": "https://www.royal-horse.com/wp-content/uploads/2022/05/LINHA-B-1-1.png",
    "name": "Potrus Junior",
    "description": "Reforça a imunidade e a fertilidade Linha Criação cavalos",
    "category": "Equinos",
    "url": "https://www.royal-horse.com/pt-br/alimentacao-cavalos/potrus-junior-racao-multiparticulas-eguas-cria-potros/"
  , "inStock": "Em Estoque" },
  {
    "image": "https://www.royal-horse.com/wp-content/uploads/2023/02/strategy-b-280-1.png",
    "name": "Strategy B-280",
    "description": "Linha Criação cavalos",
    "category": "Equinos",
    "url": "https://www.royal-horse.com/pt-br/alimentacao-cavalos/royal-horse-strategy-produto-extrusado/"
    , "inStock": "Indisponível" },
  {
    "image": "https://www.royal-horse.com/wp-content/uploads/2022/03/ABSOLUT-POTROS.png",
    "name": "Absolut Potros",
    "description": "Energia segura e suporta o desenvolvimento máximo da musculatura e das estruturas ósseas Linha Criação cavalos",
    "category": "Equinos",
    "url": "https://www.royal-horse.com/pt-br/alimentacao-cavalos/absolut-potros-multiparticulas-potros/"
  , "inStock": "Em Estoque" },
  {
    "image": "https://www.royal-horse.com/wp-content/uploads/2023/11/MULTIUSO-6.png",
    "name": "Mash C-050",
    "description": "Linha Complementos cavalos",
    "category": "Equinos",
    "url": "https://www.royal-horse.com/pt-br/alimentacao-cavalos/mash-c-050/"
  , "inStock": "Em Estoque" },
  {
    "image": "https://www.royal-horse.com/wp-content/uploads/2022/12/mineral-ultra.png",
    "name": "Mineral Ultra",
    "description": "Linha Complementos cavalos",
    "category": "Equinos",
    "url": "https://www.royal-horse.com/pt-br/alimentacao-cavalos/mineral-equinos-ultra-c-320/"
  , "inStock": "Indisponível" },
  {
    "image": "https://www.royal-horse.com/wp-content/uploads/2022/11/mineral-tropa.png",
    "name": "Mineral Tropa",
    "description": "Linha Complementos cavalos",
    "category": "Equinos",
    "url": "https://www.royal-horse.com/pt-br/alimentacao-cavalos/mineral-tropa/"
  , "inStock": "Indisponível" },
  {
    "image": "https://www.royal-horse.com/wp-content/uploads/2022/10/concentrado-protein-1.png",
    "name": "Concentrado Protein",
    "description": "Linha Complementos cavalos",
    "category": "Equinos",
    "url": "https://www.royal-horse.com/pt-br/alimentacao-cavalos/concentrado-protein-produto-peletizado-c-200/"
    , "inStock": "Indisponível" },
// adm
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2024/04/techsal-mineral-221x300.png",
    "name": "TECH SAL ADENSA",
    "description": "Sal mineral",
    "category": "Bovinos",
    "url": "https://www.admnutricaoanimal.com.br/our-products/tech-sal-adensa/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2024/01/microsoftteams-image-6-2-221x300.png",
    "name": "GÊNESE OVIN",
    "description": "Ovino",
    "category": "Ovinos",
    "url": "https://www.admnutricaoanimal.com.br/our-products/genese-ovin/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2023/08/adm-nucleo-2-1-221x300.png",
    "name": "SOYNÚCLEO PX 3",
    "description": "Núcleo para produção de rações para rebanhos leiteiros, formulado com minerais e vitaminas. GADO DE LEITE / Vacas",
    "category": "Bovinos",
    "url": "https://www.admnutricaoanimal.com.br/our-products/soynucleo-px-3/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2023/08/adm-techsal-aguas-2-221x300.png",
    "name": "TECH SAL ENERGÉTICO 54",
    "description": "destinado à suplementação mineral e proteica dos bovinos de corte nas fases de recria e engorda a campo,",
    "category": "Bovinos",
    "url": "https://www.admnutricaoanimal.com.br/our-products/tech-sal-energetico-54/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2023/08/adm-techsal-aguas-2-221x300.png",
    "name": "TECH SAL AC PROTENER",
    "description": "suplementação mineral, proteica e energética dos bovinos de corte nas fases de recria e engorda a campo",
    "category": "Bovinos",
    "url": "https://www.admnutricaoanimal.com.br/our-products/tech-sal-ac-protener/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2024/01/microsoftteams-image-6-1-221x300.png",
    "name": "TECH OVINOS INICIAL",
    "description": "alimentação de ovinos na fase inicial, do nascimento até o desmame.",
    "category": "Ovinos",
    "url": "https://www.admnutricaoanimal.com.br/our-products/tech-ovinos-inicial/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2024/01/microsoftteams-image-6-2-221x300.png",
    "name": "TECH OVINOS CAMPO",
    "description": "alimentação de ovinos após o desmame.",
    "category": "Ovinos",
    "url": "https://www.admnutricaoanimal.com.br/our-products/tech-ovinos-campo/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2024/04/techsal-mineral-221x300.png",
    "name": "TECH SAL UREADO 50 P",
    "description": "suplementação mineral e proteica dos bovinos de corte na fase de cria no período seco (inverno). ",
    "category": "Bovinos",
    "url": "https://www.admnutricaoanimal.com.br/our-products/tech-sal-ureado50p/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2024/04/techsal-mineral-221x300.png",
    "name": "TECH SAL FERTILITÉ",
    "description": "N/A",
    "category": "N/A",
    "url": "https://www.admnutricaoanimal.com.br/our-products/tech-sal-fertilite/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2023/07/adm_embalagem_azul-escuro-300x300.png",
    "name": "AMAMENTA PREMIUM",
    "description": "N/A",
    "category": "N/A",
    "url": "https://www.admnutricaoanimal.com.br/our-products/amamenta-premium/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2024/01/microsoftteams-image-6-3-221x300.png",
    "name": "TECH SAL OVINOS PROTEINADO",
    "description": "Sal proteinado para ovinos",
    "category": "Ovinos",
    "url": "https://www.admnutricaoanimal.com.br/our-products/techsal-ovinos-proteinado/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2024/04/techsal-secas-221x300.png",
    "name": "TECH SAL SECA 40",
    "description": "Sal seca",
    "category": "N/A",
    "url": "https://www.admnutricaoanimal.com.br/our-products/techsal-seca-40/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2023/07/adm_camanutri-30kg-221x300.png",
    "name": "CAMANUTRI 37 J",
    "description": "cultivos semi-intensivos e intensivos. DENSIDADE: até 70 cam/m².",
    "category": "Camarões",
    "url": "https://www.admnutricaoanimal.com.br/our-products/camanutri-37-j/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2023/07/adm_camanutri-30kg-221x300.png",
    "name": "CAMANUTRI 35 J",
    "description": "cultivos semi-intensivos e intensivos. DENSIDADE: até 50 cam/m².",
    "category": "Camarões",
    "url": "https://www.admnutricaoanimal.com.br/our-products/camanutri-35-j/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2023/07/adm_camanutri-30kg-221x300.png",
    "name": "CAMANUTRI 30 J",
    "description": "semi-intensivos e intensivos DENSIDADE: até 20 cam/m².",
    "category": "Camarões",
    "url": "https://www.admnutricaoanimal.com.br/our-products/camanutri-30-j/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2023/07/adm_density-30kg-221x300.png",
    "name": "DENSITY 40CR2",
    "description": "N/A",
    "category": "N/A",
    "url": "https://www.admnutricaoanimal.com.br/our-products/density-49cr2/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2023/07/adm_density-30kg-221x300.png",
    "name": "DENSITY 40CR1",
    "description": "N/A",
    "category": "N/A",
    "url": "https://www.admnutricaoanimal.com.br/our-products/density-40cr1/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2023/07/adm_density-30kg-221x300.png",
    "name": "DENSITY 38J",
    "description": "N/A",
    "category": "N/A",
    "url": "https://www.admnutricaoanimal.com.br/our-products/density-38j/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2023/07/adm_camanutri-30kg-221x300.png",
    "name": "CAMANUTRI ANTIESTRESSE",
    "description": "N/A",
    "category": "N/A",
    "url": "https://www.admnutricaoanimal.com.br/our-products/camanutri-antiestresse-2/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2023/07/laguna-pos-larvas-e-carnivoros-221x300.png",
    "name": "LAGUNA CARNÍVOROS PINTADO 38",
    "description": "N/A",
    "category": "N/A",
    "url": "https://www.admnutricaoanimal.com.br/our-products/laguna-carnivoros-pintado-38/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2023/07/laguna-pos-larvas-e-carnivoros-221x300.png",
    "name": "LAGUNA CARNÍVOROS PINTADO 36",
    "description": "N/A",
    "category": "N/A",
    "url": "https://www.admnutricaoanimal.com.br/our-products/laguna-carnivoros-pintado-36/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2023/07/nutripiscis-alenivos-e-juvenis-221x300.png",
    "name": "NUTRIPISCIS IMMUNITY+ 3 A 4MM",
    "description": "N/A",
    "category": "N/A",
    "url": "https://www.admnutricaoanimal.com.br/our-products/nutripiscis-immunity-3a4mm/",
  "inStock": "Indisponível"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2023/07/nutripiscis-tilapia-221x300.png",
    "name": "NUTRIPISCIS TR 32 HT 6 A 8MM",
    "description": "N/A",
    "category": "N/A",
    "url": "https://www.admnutricaoanimal.com.br/our-products/nutripiscis-tr32-ht-6a8mm/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2023/07/nutripiscis-alenivos-e-juvenis-221x300.png",
    "name": "NUTRIPISCIS AL 4 A 6MM",
    "description": "N/A",
    "category": "N/A",
    "url": "https://www.admnutricaoanimal.com.br/our-products/nutripiscis-al-4a6mm/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2023/07/nutripiscis-alenivos-e-juvenis-221x300.png",
    "name": "NUTRIPISCIS JUVENIL 36 3 A 4MM",
    "description": "N/A",
    "category": "Peixes",
    "url": "https://www.admnutricaoanimal.com.br/our-products/nutripiscis-juvenil36-3a4mm/",
    "inStock": "Indisponível"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2023/07/nutripiscis-alenivos-e-juvenis-221x300.png",
    "name": "NUTRIPISCIS IMMUNITY + 2 A 3MM",
    "description": "N/A",
    "category": "Peixes",
    "url": "https://www.admnutricaoanimal.com.br/our-products/nutripiscis-immunity-2a3mm/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2023/07/laguna-pos-larvas-e-carnivoros-221x300.png",
    "name": "BIOTRUTAS 42 ASTX 10 A 12MM",
    "description": "Peixes",
    "category": "Peixes",
    "url": "https://www.admnutricaoanimal.com.br/our-products/biotrutas42-astx-10a12mm/",
    "inStock": "Indisponível"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2023/07/laguna-pos-larvas-e-carnivoros-221x300.png",
    "name": "BIOTRUTAS 42% 10 A 12MM",
    "description": "Peixes",
    "category": "Peixes",
    "url": "https://www.admnutricaoanimal.com.br/our-products/biotrutas42-10a12mm/",
    "inStock": "Indisponível"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2023/07/laguna-pos-larvas-e-carnivoros-221x300.png",
    "name": "BIOTRUTAS 44% 3 A 4MM",
    "description": "Peixes",
    "category": "Peixes",
    "url": "https://www.admnutricaoanimal.com.br/our-products/biotrutas44-3a4/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2023/07/laguna-pos-larvas-e-carnivoros-221x300.png",
    "name": "LAGUNA CARNÍVOROS CRESCIMENTO 45 4 A 6MM",
    "description": "Peixes carnínoros crescimento",
    "category": "Peixes",
    "url": "https://www.admnutricaoanimal.com.br/our-products/laguna-carnivoros-crescimento45-4a6/",
    "inStock": "Indisponível"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2023/07/laguna-pos-larvas-e-carnivoros-221x300.png",
    "name": "LAGUNA CARNÍVOROS ENGORDA 40 8 A 10MM",
    "description": "Peixes carnínoros engorda",
    "category": "Peixes",
    "url": "https://www.admnutricaoanimal.com.br/our-products/laguna-carnivoros-engorda40-8a10/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2023/08/passaros-249x300.png",
    "name": "PÁSSAROS",
    "description": "Pássaros",
    "category": "Aves",
    "url": "https://www.admnutricaoanimal.com.br/our-products/passaros/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2023/08/mockup-frontal_linha-do-produtor-codornas-182x300.png",
    "name": "CODORNAS",
    "description": "Codornas",
    "category": "Aves",
    "url": "https://www.admnutricaoanimal.com.br/our-products/codornas/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2023/08/adm-nucleo-2-1-221x300.png",
    "name": "NÚCLEO POSTURA 4%",
    "description": "N/A",
    "category": "Aves",
    "url": "https://www.admnutricaoanimal.com.br/our-products/nucleo-postura-4-2/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2023/08/mockup-frontal_linha-do-produtor-aves-182x300.png",
    "name": "POSTURA CONCENTRADO",
    "description": "Postura concentrado",
    "category": "Aves",
    "url": "https://www.admnutricaoanimal.com.br/our-products/postura-concentrado/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2023/08/mockup-frontal_linha-do-produtor-aves-182x300.png",
    "name": "POSTURA 17",
    "description": "Postura 17",
    "category": "Aves",
    "url": "https://www.admnutricaoanimal.com.br/our-products/postura-17/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2023/08/adm-nucleo-2-1-221x300.png",
    "name": "NÚCLEO FRANGOS ENGORDA 5%",
    "description": "N/A",
    "category": "Aves",
    "url": "https://www.admnutricaoanimal.com.br/our-products/nucleo-frangos-engorda-5/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2023/08/adm-nucleo-2-1-221x300.png",
    "name": "NÚCLEO FRANGOS INICIAL 5%",
    "description": "N/A",
    "category": "Aves",
    "url": "https://www.admnutricaoanimal.com.br/our-products/nucleo-frangos-inciail-5/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2023/08/mockup-frontal_linha-do-produtor-aves-182x300.png",
    "name": "FRANGO CONCENTRADO",
    "description": "N/A",
    "category": "Aves",
    "url": "https://www.admnutricaoanimal.com.br/our-products/frango-concentrado/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2023/08/mockup-frontal_linha-do-produtor-aves-182x300.png",
    "name": "FRANGOS ENGORDA 35 CONCENTRADO",
    "description": "N/A",
    "category": "Aves",
    "url": "https://www.admnutricaoanimal.com.br/our-products/frangos-engorda-35-concentrado/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2023/08/mockup-frontal_linha-do-produtor-aves-182x300.png",
    "name": "FRANGOS INICIAL 40 CONCENTRADO",
    "description": "N/A",
    "category": "Aves",
    "url": "https://www.admnutricaoanimal.com.br/our-products/frangos-inicial-40-concentrado/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2023/08/mockup-frontal_linha-do-produtor-aves-182x300.png",
    "name": "FRANGOS ENGORDA",
    "description": "Frango engorda",
    "category": "Aves",
    "url": "https://www.admnutricaoanimal.com.br/our-products/frangos-engorda/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2023/08/mockup-frontal_linha-do-produtor-aves-182x300.png",
    "name": "FRANGOS INICIAL",
    "description": "Frango inicial",
    "category": "Aves",
    "url": "https://www.admnutricaoanimal.com.br/our-products/frangos-inicial/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2023/08/adm-nucleo-2-1-221x300.png",
    "name": "NÚCLEO SUÍNOS REPRODUÇÃO 3%",
    "description": "N/A",
    "category": "N/A",
    "url": "https://www.admnutricaoanimal.com.br/our-products/nucleo-suinos-reproducao-3/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2023/08/adm-nucleo-2-1-221x300.png",
    "name": "NÚCLEO SUÍNOS\nCRESCIMENTO E TERMINAÇÃO 3%",
    "description": "N/A",
    "category": "N/A",
    "url": "https://www.admnutricaoanimal.com.br/our-products/nucleo-suinos-crescimento-terminacao/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2023/08/adm-nucleo-2-1-221x300.png",
    "name": "NÚCLEO SUÍNOS INICIAL 3%",
    "description": "N/A",
    "category": "N/A",
    "url": "https://www.admnutricaoanimal.com.br/our-products/nucleo-suinos-inicial-3/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2023/08/mockup-frontal_linha-do-produtor-suinos-182x300.png",
    "name": "SUÍNOS CONCENTRADO",
    "description": "Ração para porcos ( Suínos ) concentrado",
    "category": "Suínos",
    "url": "https://www.admnutricaoanimal.com.br/our-products/suinos-concentrado/",
    "inStock": "Indisponível"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2023/08/mockup-frontal_linha-do-produtor-suinos-182x300.png",
    "name": "SUÍNOS CRIADEIRA",
    "description": "N/A",
    "category": "N/A",
    "url": "https://www.admnutricaoanimal.com.br/our-products/suinos-criadeira-2/",
    "inStock": "Indisponível"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2023/08/mockup-frontal_linha-do-produtor-suinos-182x300.png",
    "name": "SUÍNOS TERMINAÇÃO",
    "description": "N/A",
    "category": "N/A",
    "url": "https://www.admnutricaoanimal.com.br/our-products/suinos-terminacao-2/",
    "inStock": "Indisponível"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2023/08/mockup-frontal_linha-do-produtor-suinos-182x300.png",
    "name": "SUÍNOS CRIADEIRA",
    "description": "Ração para porcos ( Suínos ) criadeira",
    "category": "Suínos",
    "url": "https://www.admnutricaoanimal.com.br/our-products/suinos-criadeira/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2023/08/mockup-frontal_linha-do-produtor-suinos-182x300.png",
    "name": "SUÍNOS TERMINAÇÃO",
    "description": "Ração para porcos ( Suínos ) terminação",
    "category": "Suínos",
    "url": "https://www.admnutricaoanimal.com.br/our-products/suinos-terminacao/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2023/08/mockup-frontal_linha-do-produtor-suinos-182x300.png",
    "name": "SUÍNOS CRESCIMENTO ENGORDA",
    "description": "Ração para porcos ( Suínos ) crescimento e para engorda",
    "category": "Suínos",
    "url": "https://www.admnutricaoanimal.com.br/our-products/suinos-crescimento-engorda/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2023/08/mockup-frontal_linha-do-produtor-suinos-182x300.png",
    "name": "SUÍNOS INICIAL",
    "description": "Ração para porcos ( Suínos ) inicial",
    "category": "Suínos",
    "url": "https://www.admnutricaoanimal.com.br/our-products/suinos-inicial/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2023/07/suino_liso-221x300.png",
    "name": "DO CAMPO SUÍNOS",
    "description": "Ração para porcos ( Suínos )",
    "category": "Suínos",
    "url": "https://www.admnutricaoanimal.com.br/our-products/do-campo-suinos/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2023/08/mockup-frontal_linha-do-produtor-coelhos-182x300.png",
    "name": "COELHOS",
    "description": "Ração para coelhos",
    "category": "Coelhos",
    "url": "https://www.admnutricaoanimal.com.br/our-products/coelhos/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2023/07/coelho_liso-1-221x300.png",
    "name": "DO CAMPO COELHOS",
    "description": "Ração para coelhos",
    "category": "Coelhos",
    "url": "https://www.admnutricaoanimal.com.br/our-products/do-campo-coelhos/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://www.admnutricaoanimal.com.br/wp-content/uploads/2023/07/ceva_liso-1-221x300.png",
    "name": "DO CAMPO CEVA",
    "description": "Ceva para peixes",
    "category": "Peixe",
    "url": "https://www.admnutricaoanimal.com.br/our-products/do-campo-ceva/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://pbps.com.br/sites/default/files/styles/node/public/imagens-produto/dsc_4406.jpg?itok=r9LB8tsO",
    "name": "Mochador Elétrico (modelo 415004)",
    "description": "2 eras com diâmetros diferentes para mochação de bezerros jovens e erados / Não dá choque no animal / Empunhadura ergonômica em Madeira - em Aço Inox e blindada / Cordão elétrico de 6,20 metros",
    "category": "Ferramentas",
    "url": "https://pbps.com.br/produto/mochador-eletrico-2-eras-127v-modelo-415004",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://pbps.com.br/sites/default/files/styles/node/public/imagens-produto/dsc_4447.jpg?itok=GAPPU_6v",
    "name": "Ebulidor Grande 2200W",
    "description": "Indicado para a higienização de tanque de expansão, ordenhas e equipamentos utilizados na coleta e armazenamento do leite",
    "category": "Ferramentas",
    "url": "https://pbps.com.br/produto/ebulidor-grande-2200w-127v-modelo-415008",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://www.itacorda.com.br/wp-content/uploads/2023/01/Design-sem-nome-15.png",
    "name": "Corda Trançada Eco-Friendly",
    "description": "A corda trançada Eco-friendly P.E.T, é um dos produtos da linha ecológica de cordas PET feita a partir do reaproveitamento de garrafas pet.",
    "category": "Ferramentas",
    "url": "https://www.itacorda.com.br/produto/corda-trancada-eco-friendly-p-e-t-carretel-compacto/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://static.wixstatic.com/media/cb1d30_22bdfeee1cfc4f6cbda80b7253043667~mv2.png/v1/fill/w_534,h_534,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/cb1d30_22bdfeee1cfc4f6cbda80b7253043667~mv2.png",
    "name": "Quatree Life",
    "description": "Quatree Life é um alimento Premium Especial Natural formulado com antioxidantes naturais e complexo de vegetais para promover mais vitalidade e bem-estar para o seu cão.",
    "category": "Cães",
    "url": "https://www.quatreepet.com.br/caes-life",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://www.champion.ind.br/cdn/shop/files/diflys31kg-01_1024x1024@2x.jpg?v=1718822153",
    "name": "Difly S3 Champion",
    "description": "Controle do carrapato e da mosca-dos-chifres, deve ser misturado ao sal branco, sal mineral, proteinado ou mesmo na ração e em seguida basta deixar à vontade no cocho.",
    "category": "Bovinos",
    "url": "https://www.champion.ind.br/collections/mineralizacao/products/difly-s3",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://biofarm.com.br/wp-content/webp-express/webp-images/uploads/2024/06/GLICOSE-50-50ml-FMV040249.png.webp",
    "name": "GLICOSE 50%",
    "description": "suplemento energético nos estados infecciosos e desidratações,",
    "category": "Cães",
    "url": "https://biofarm.com.br/produtos/glicose-50-2/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://sementesdaterra.com.br/wp-content/webp-express/webp-images/uploads/2022/08/MIYAGUI-768x768.jpg.webp",
    "name": "Mombaça",
    "description": "Panicum maximum é conhecida devido a sua alta produtividade e qualidade de pastagem",
    "category": "Sementes",
    "url": "https://sementesdaterra.com.br/produto/mombaca/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://www.sementesfeltrin.com.br/imagens_importadas/780.jpg",
    "name": "ABÓBORA B8A",
    "description": "Hortaliças - ABÓBORA TETSUKABUTO JAPONESA, 90 - 120 dias; Produtividades 15 - 18 ton/ha ",
    "category": "Sementes",
    "url": "https://www.sementesfeltrin.com.br/produtos/ab-bora-b8a/19",
    "inStock": "Em Estoque"
  },{
    "image": "https://alinutri.ind.br/wp-content/uploads/2023/09/NATURE_FORM_FL_RP_M.png",
    "name": "Nature Cães Filhotes Raças P e M 15kg",
    "description": "Alinutri Nature Fórmula Super Premium Cães Filhotes Raças Pequenas e Médias é indicado para cães filhotes de todas as raças.",
    "category": "Cães",
    "url": "https://alinutri.ind.br/produtos/pet/nature-formula-super-premium-caes-filhotes-racas-pequenas-e-medias/",
    "inStock": "Em Estoque"
  },
  {
    "image": "https://alinutri.ind.br/wp-content/uploads/2023/09/NATURE_FORM_GATOS_AD.png",
    "name": "Nature Super Premium Gatos Adultos",
    "description": "Alinutri Nature Fórmula Super Premium Gatos Adultos é indicado para alimentação de gatos adultos de todas as raças. ",
    "category": "Gatos",
    "url": "https://alinutri.ind.br/produtos/pet/nature-formula-premium-especial-gatos-adultos/",
    "inStock": "Em Estoque"
  }
  ,{
    "image": "https://alinutri.ind.br/wp-content/uploads/2023/09/Xerifeprotein_adultos.png",
    "name": "Xerife Protein 25 KG",
    "description": "Xerife Protein é indicado para cães adultos. Preparações destinadas a fornecer ao animal a totalidade dos elementos nutritivos necessários.",
    "category": "Cães",
    "url": "https://alinutri.ind.br/produtos/pet/xerife-protein-2/",
    "inStock": "Em Estoque"
  },
  {
    "image": "",
    "name": "N/A",
    "description": "N/A",
    "category": "N/A",
    "url": "",
    "inStock": "Indisponível"
  },        
];