import React, { useEffect, useState } from 'react';
import axiosInstance from '../../axiosConfig';

const ContactDataManagement = () => {
  const [contactInfo, setContactInfo] = useState({
    phone: '',
    email: '',
    address: ''
  });
  const [socialMediaLinks, setSocialMediaLinks] = useState([]);
  const [newSocialMedia, setNewSocialMedia] = useState({
    platform: '',
    link: ''
  });
  const [whatsAppInfo, setWhatsAppInfo] = useState({
    phoneNumber: '',
    messageTemplate: ''
  });

  useEffect(() => {
    // Fetch contact info
    axiosInstance.get('/contact-info')
      .then(response => setContactInfo(response.data))
      .catch(error => console.error('Error fetching contact info:', error));
    
    // Fetch social media links
    axiosInstance.get('/social-media')
      .then(response => setSocialMediaLinks(response.data))
      .catch(error => console.error('Error fetching social media links:', error));
    
    // Fetch WhatsApp info
    axiosInstance.get('/whatsapp-info')
      .then(response => setWhatsAppInfo(response.data))
      .catch(error => console.error('Error fetching WhatsApp info:', error));
  }, []);

  const handleUpdateContactInfo = () => {
    axiosInstance.put('/contact-info', contactInfo)
      .then(response => {
        setContactInfo(response.data);
        alert('Contact info updated successfully');
      })
      .catch(error => console.error('Error updating contact info:', error));
  };

  const handleCreateSocialMedia = () => {
    axiosInstance.post('/social-media', newSocialMedia)
      .then(response => {
        setSocialMediaLinks([...socialMediaLinks, response.data]);
        setNewSocialMedia({ platform: '', link: '' });
        alert('Social media link created successfully');
      })
      .catch(error => console.error('Error creating social media link:', error));
  };

  const handleUpdateSocialMedia = (id) => {
    const updatedLink = socialMediaLinks.find(link => link.id === id);
    axiosInstance.put(`/social-media/${id}`, updatedLink)
      .then(response => {
        const updatedLinks = socialMediaLinks.map(link =>
          link.id === id ? response.data : link
        );
        setSocialMediaLinks(updatedLinks);
        alert('Social media link updated successfully');
      })
      .catch(error => console.error('Error updating social media link:', error));
  };

  const handleDeleteSocialMedia = (id) => {
    axiosInstance.delete(`/social-media/${id}`)
      .then(() => {
        setSocialMediaLinks(socialMediaLinks.filter(link => link.id !== id));
        alert('Social media link deleted successfully');
      })
      .catch(error => console.error('Error deleting social media link:', error));
  };

  const handleUpdateWhatsAppInfo = () => {
    axiosInstance.put('/whatsapp-info', whatsAppInfo)
      .then(response => {
        setWhatsAppInfo(response.data);
        alert('WhatsApp info updated successfully');
      })
      .catch(error => console.error('Error updating WhatsApp info:', error));
  };

  return (
    <div className="contact-data-management">
      <h1>Contact Data Management</h1>

      {/* Contact Info Management */}
      <div className="contact-info">
        <h2>Contact Information</h2>
        <input
          type="text"
          placeholder="Phone"
          value={contactInfo.phone}
          onChange={(e) => setContactInfo({ ...contactInfo, phone: e.target.value })}
        />
        <input
          type="text"
          placeholder="Email"
          value={contactInfo.email}
          onChange={(e) => setContactInfo({ ...contactInfo, email: e.target.value })}
        />
        <input
          type="text"
          placeholder="Address"
          value={contactInfo.address}
          onChange={(e) => setContactInfo({ ...contactInfo, address: e.target.value })}
        />
        <button onClick={handleUpdateContactInfo}>Update Contact Info</button>
      </div>

      {/* Social Media Management */}
      <div className="social-media">
        <h2>Social Media Links</h2>
        <ul>
          {socialMediaLinks.map(link => (
            <li key={link.id}>
              <p><strong>Platform:</strong> {link.platform}</p>
              <p><strong>Link:</strong> <a href={link.link} target="_blank" rel="noopener noreferrer">{link.link}</a></p>
              <button onClick={() => handleUpdateSocialMedia(link.id)}>Edit</button>
              <button onClick={() => handleDeleteSocialMedia(link.id)}>Delete</button>
            </li>
          ))}
        </ul>
        <h3>Add New Social Media</h3>
        <input
          type="text"
          placeholder="Platform"
          value={newSocialMedia.platform}
          onChange={(e) => setNewSocialMedia({ ...newSocialMedia, platform: e.target.value })}
        />
        <input
          type="text"
          placeholder="Link"
          value={newSocialMedia.link}
          onChange={(e) => setNewSocialMedia({ ...newSocialMedia, link: e.target.value })}
        />
        <button onClick={handleCreateSocialMedia}>Add Social Media Link</button>
      </div>

      {/* WhatsApp Management */}
      <div className="whatsapp-info">
        <h2>WhatsApp Information</h2>
        <input
          type="text"
          placeholder="Phone Number"
          value={whatsAppInfo.phoneNumber}
          onChange={(e) => setWhatsAppInfo({ ...whatsAppInfo, phoneNumber: e.target.value })}
        />
        <input
          type="text"
          placeholder="Message Template"
          value={whatsAppInfo.messageTemplate}
          onChange={(e) => setWhatsAppInfo({ ...whatsAppInfo, messageTemplate: e.target.value })}
        />
        <button onClick={handleUpdateWhatsAppInfo}>Update WhatsApp Info</button>
      </div>
    </div>
  );
};

export default ContactDataManagement;
