import React, { useState } from 'react';

const Keynotes = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      title: "Nossa Missão",
      content: (
        <p className="text-blue-700">
          A AgroVeterinária Estrela busca posicionar-se como a principal referência em rações e produtos veterinários no Leste de Minas Gerais, oferecendo produtos de qualidade, bom preço e um atendimento veterinário de excelência.
        </p>
      ),
    },
    {
      title: "Melhores Marcas",
      content: (
        <ul className="list-disc pl-5 space-y-2">
          <li><b>Marcas de ração:</b></li> 
          <ul className="list-disc pl-5 space-y-2">
            <li>ADM</li>
            <li>Royal Horse</li>
            <li>Prata Brito</li>
            <li>Ita Cordas</li>
            <li>Quatree</li>
            <li>UnsetmaxChampion Saúde Animal</li>
            <li>Instet Max</li>
            <li>Botas da Serra</li>
            <li>Botas quatro s</li>
            <li>Bior Farm</li>
            <li>Sementes Da Terra</li>
            <li>Feltrin Sementes</li>
            <li>Alinutri Nutrição Animal</li>
            <li>Gaiolas Eldorado</li>
          </ul>
        </ul>
      ),
    },
    {
      title: "Por que escolher a AgroVeterinária Estrela?",
      content: (
        <ul className="list-disc pl-5 space-y-2">
          <li>Qualidade: Oferecemos produtos de alta qualidade para garantir a saúde e o bem-estar dos seus animais.</li>
          <li>Variedade: Encontre tudo o que você precisa em um só lugar, desde rações para cães e gatos até produtos para equinos e aves.</li>
          <li>Preço: Trabalhamos com preços competitivos, buscando sempre o melhor custo-benefício para você.</li>
          <li>Atendimento: Nossa equipe de veterinários está sempre pronta para te atender e oferecer as melhores soluções para a saúde do seu animal.</li>
        </ul>
      ),
    },
    {
      title: "Nossos Serviços",
      content: (
        <ul className="list-disc pl-5 space-y-2">
          <li>Venda de rações: Para cães, gatos, equinos, aves, peixes, roedores e outros animais.</li>
          <li>Produtos veterinários: Medicamentos, vacinas, vermífugos, acessórios e muito mais.</li>
          <li>Atendimento veterinário: Consultas e prescrições</li>
          <li>Agropecuária</li>
          <li>Produtos de qualidade</li>
          <li>Custo-benefício</li>
          <li>Bom preço</li>
        </ul>
      ),
    },
  ];

  return (
    <div className="w-full bg-blue-100 p-4">
      <div className="max-w-7xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden w-full">
        <header className="bg-gradient-to-r from-blue-500 to-blue-700 p-4">
          <h1 className="text-2xl md:text-3xl font-bold text-white text-center">AgroVeterinária Estrela</h1>
        </header>

        <div className="p-4">
          <nav className="flex flex-wrap justify-around border-b mb-4">
            {tabs.map((tab, index) => (
              <button
                key={index}
                className={`text-sm md:text-lg py-2 px-3 md:px-4 transition-colors duration-300 ease-in-out ${
                  activeTab === index
                    ? "border-b-4 border-blue-500 text-blue-500 font-semibold"
                    : "text-gray-600 hover:text-blue-500"
                }`}
                onClick={() => setActiveTab(index)}
              >
                {tab.title}
              </button>
            ))}
          </nav>

          <div className="p-2 md:p-4">
            {tabs[activeTab].content}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Keynotes;
