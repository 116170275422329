import React, { useState, useEffect } from 'react';
import { FaInstagram, FaFacebookF, FaYoutube, FaWhatsapp, FaBars, FaUser } from 'react-icons/fa';
import logo from '../images/whitelogo.png';

const Header = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 50);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <header className={`fixed top-0 left-0 w-full z-50 transition-colors duration-300 ${isScrolled ? 'bg-white text-blue-700 shadow-md' : 'bg-gray-800 text-white'}`}>
            <div className="container mx-auto flex justify-between items-center p-4">
                <div className="flex items-center">
                <a href="/" id="logo">
                    <img src={logo} alt="Logo" className="h-10 mr-3" />
</a>
                    <h1><a href="/" className="text-2xl font-bold">AgroVeterinária Estrela</a></h1>
                </div>

                <div className="hidden md:flex space-x-4">
                    <a href="https://www.instagram.com/agroveterinariaestrela/" target="_blank" rel="noopener noreferrer">
                        <FaInstagram className="hover:text-blue-700 transition-colors duration-300" />
                    </a>
                    <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                        <FaFacebookF className="hover:text-blue-700 transition-colors duration-300" />
                    </a>
                    <a href="https://www.youtube.com/@agroveterinariaestrela6945" target="_blank" rel="noopener noreferrer">
                        <FaYoutube className="hover:text-blue-700 transition-colors duration-300" />
                    </a>
                    <a href="https://wa.me/5533984098457" target="_blank" rel="noopener noreferrer">
                        <FaWhatsapp className="hover:text-blue-700 transition-colors duration-300" />
                    </a>
                    <a href="/admin" target="_blank" rel="noopener noreferrer">
                    <FaUser className="hover:text-blue-700 transition-colors duration-300" />
                    </a>

                </div>

                <div className="md:hidden">
                    <button onClick={() => setMenuOpen(!menuOpen)}>
                        <FaBars className="text-2xl" />
                    </button>
                </div>
            </div>

            {menuOpen && (
                <div className={`md:hidden bg-gray-800 ${isScrolled ? 'bg-white text-blue-700' : 'text-white'} transition-colors duration-300`}>
                    <div className="flex flex-col items-center space-y-4 p-4">
                        <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" className="hover:text-blue-700">
                            <FaInstagram />
                        </a>
                        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" className="hover:text-blue-700">
                            <FaFacebookF />
                        </a>
                        <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer" className="hover:text-blue-700">
                            <FaYoutube />
                        </a>
                        <a href="https://wa.me/5533984098457" target="_blank" rel="noopener noreferrer" className="hover:text-blue-700">
                            <FaWhatsapp />
                        </a>
                    </div>
                </div>
            )}
        </header>
    );
};

export default Header;
