import axios from 'axios';

// Create an instance of Axios
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, // Use the base URL from .env
  timeout: parseInt(process.env.REACT_APP_API_TIMEOUT, 10), // Optional: set a timeout
  headers: {
    'Content-Type': 'application/json',
  },
});

// Interceptor to add Authorization header to every request if the token exists
axiosInstance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
