import React, { createContext, useState } from 'react';

export const DashboardAuthContext = createContext();

export const DashboardAuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const login = () => setIsAuthenticated(true);
    const logout = () => setIsAuthenticated(false);

    return (
        <DashboardAuthContext.Provider value={{ isAuthenticated, login, logout }}>
            {children}
        </DashboardAuthContext.Provider>
    );
};
