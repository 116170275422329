import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import AdminLogin from './dashboard/pages/AdminLogin';
import DashboardHome from './dashboard/pages/DashboardHome';
import ContactDataManagement from './dashboard/pages/ContactDataManagement';
import HeroManagement from './dashboard/pages/HeroManagement';
import ProductsManagement from './dashboard/pages/ProductsManagement';
import DashboardLayout from './dashboard/layouts/DashboardLayout';
import { DashboardAuthProvider, DashboardAuthContext } from './dashboard/context/DashboardAuthContext';

// ProtectedRoute Component
const ProtectedRoute = ({ element }) => {
  const { isAuthenticated } = useContext(DashboardAuthContext);

  return isAuthenticated ? element : <Navigate to="/admin" />;
};

const App = () => {
  return (
    <DashboardAuthProvider>
      <Router>
        <div className="min-h-screen flex flex-col">
          <Routes>
            <Route path="/" element={<Home />} />

            {/* Admin Login */}
            <Route path="/admin" element={<AdminLogin />} />

            {/* Protected Dashboard Routes */}
            <Route
              path="/dashboard"
              element={<ProtectedRoute element={<DashboardLayout />} />}
            >
              <Route index element={<DashboardHome />} />
              <Route path="contact-data" element={<ContactDataManagement />} />
              <Route path="hero-management" element={<HeroManagement />} />
              <Route path="products-management" element={<ProductsManagement />} />
            </Route>
          </Routes>
        </div>
      </Router>
    </DashboardAuthProvider>
  );
};

export default App;
