// src/dashboard/components/Sidebar.js
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { HomeIcon, CubeIcon, UsersIcon, LogoutIcon } from '@heroicons/react/outline';

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`fixed top-0 left-0 h-full bg-blue-900 text-white ${isOpen ? 'w-64' : 'w-20'} transition-all duration-300 shadow-lg`}>
      <div className="flex items-center justify-between p-4">
        <button onClick={() => setIsOpen(!isOpen)}>
          <span className="text-2xl font-semibold">{isOpen ? 'Admin' : 'A'}</span>
        </button>
      </div>
      <nav className="mt-10">
        <Link to="/dashboard" className="flex items-center p-4 hover:bg-blue-700">
          <HomeIcon className="h-6 w-6" />
          {isOpen && <span className="ml-4">Dashboard</span>}
        </Link>
        <Link to="/dashboard/products" className="flex items-center p-4 hover:bg-blue-700">
          <CubeIcon className="h-6 w-6" />
          {isOpen && <span className="ml-4">Products</span>}
        </Link>
        <Link to="/dashboard/hero" className="flex items-center p-4 hover:bg-blue-700">
          <UsersIcon className="h-6 w-6" />
          {isOpen && <span className="ml-4">Hero</span>}
        </Link>
        <Link to="/dashboard/contact-data" className="flex items-center p-4 hover:bg-blue-700">
          <UsersIcon className="h-6 w-6" />
          {isOpen && <span className="ml-4">Contact Data</span>}
        </Link>
      </nav>
      <div className="absolute bottom-0 left-0 w-full">
        <Link to="/login" className="flex items-center p-4 hover:bg-blue-700">
          <LogoutIcon className="h-6 w-6" />
          {isOpen && <span className="ml-4">Logout</span>}
        </Link>
      </div>
    </div>
  );
};

export default Sidebar;
