import React, { useEffect, useState } from 'react';
import axiosInstance from '../../axiosConfig';

const HeroManagement = () => {
  const [heroSlides, setHeroSlides] = useState([]);
  const [newSlide, setNewSlide] = useState({ title: '', image: '' });
  const [editingSlide, setEditingSlide] = useState(null);

  const [heroSettings, setHeroSettings] = useState({
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    fade: false,
  });

  useEffect(() => {
    // Fetch hero slides
    axiosInstance.get('/hero/slides')
      .then(response => setHeroSlides(response.data))
      .catch(error => console.error('Error fetching hero slides:', error));

    // Fetch hero settings
    axiosInstance.get('/hero/settings')
      .then(response => setHeroSettings(response.data))
      .catch(error => console.error('Error fetching hero settings:', error));
  }, []);

  const handleCreateSlide = () => {
    axiosInstance.post('/hero/slides', newSlide)
      .then(response => {
        setHeroSlides([...heroSlides, response.data]);
        setNewSlide({ title: '', image: '' });
        alert('Hero slide created successfully');
      })
      .catch(error => console.error('Error creating hero slide:', error));
  };

  const handleUpdateSlide = (slideId) => {
    axiosInstance.put(`/hero/slides/${slideId}`, editingSlide)
      .then(response => {
        const updatedSlides = heroSlides.map(slide =>
          slide.id === slideId ? response.data : slide
        );
        setHeroSlides(updatedSlides);
        setEditingSlide(null);
        alert('Hero slide updated successfully');
      })
      .catch(error => console.error('Error updating hero slide:', error));
  };

  const handleDeleteSlide = (slideId) => {
    axiosInstance.delete(`/hero/slides/${slideId}`)
      .then(() => {
        setHeroSlides(heroSlides.filter(slide => slide.id !== slideId));
        alert('Hero slide deleted successfully');
      })
      .catch(error => console.error('Error deleting hero slide:', error));
  };

  const handleUpdateSettings = () => {
    axiosInstance.put('/hero/settings', heroSettings)
      .then(response => {
        setHeroSettings(response.data);
        alert('Hero settings updated successfully');
      })
      .catch(error => console.error('Error updating hero settings:', error));
  };

  return (
    <div className="hero-management">
      <h1>Hero Management</h1>

      {/* Hero Slides Management */}
      <div className="add-slide">
        <h2>Add New Slide</h2>
        <input
          type="text"
          placeholder="Title"
          value={newSlide.title}
          onChange={(e) => setNewSlide({ ...newSlide, title: e.target.value })}
        />
        <input
          type="text"
          placeholder="Image URL"
          value={newSlide.image}
          onChange={(e) => setNewSlide({ ...newSlide, image: e.target.value })}
        />
        <button onClick={handleCreateSlide}>Add Slide</button>
      </div>

      <div className="slides-list">
        <h2>Current Slides</h2>
        <ul>
          {heroSlides.map(slide => (
            <li key={slide.id}>
              <p><strong>Title:</strong> {slide.title}</p>
              <p><strong>Image:</strong> <img src={slide.image} alt={slide.title} /></p>
              <button onClick={() => setEditingSlide(slide)}>Edit</button>
              <button onClick={() => handleDeleteSlide(slide.id)}>Delete</button>
              {editingSlide && editingSlide.id === slide.id && (
                <div className="edit-slide">
                  <input
                    type="text"
                    value={editingSlide.title}
                    onChange={(e) => setEditingSlide({ ...editingSlide, title: e.target.value })}
                  />
                  <input
                    type="text"
                    value={editingSlide.image}
                    onChange={(e) => setEditingSlide({ ...editingSlide, image: e.target.value })}
                  />
                  <button onClick={() => handleUpdateSlide(slide.id)}>Save Changes</button>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>

      {/* Hero Settings Management */}
      <div className="hero-settings">
        <h2>Hero Settings</h2>
        <label>
          Dots:
          <input
            type="checkbox"
            checked={heroSettings.dots}
            onChange={(e) => setHeroSettings({ ...heroSettings, dots: e.target.checked })}
          />
        </label>
        <label>
          Infinite:
          <input
            type="checkbox"
            checked={heroSettings.infinite}
            onChange={(e) => setHeroSettings({ ...heroSettings, infinite: e.target.checked })}
          />
        </label>
        <label>
          Speed:
          <input
            type="number"
            value={heroSettings.speed}
            onChange={(e) => setHeroSettings({ ...heroSettings, speed: parseInt(e.target.value) })}
          />
        </label>
        <label>
          Slides to Show:
          <input
            type="number"
            value={heroSettings.slidesToShow}
            onChange={(e) => setHeroSettings({ ...heroSettings, slidesToShow: parseInt(e.target.value) })}
          />
        </label>
        <label>
          Slides to Scroll:
          <input
            type="number"
            value={heroSettings.slidesToScroll}
            onChange={(e) => setHeroSettings({ ...heroSettings, slidesToScroll: parseInt(e.target.value) })}
          />
        </label>
        <label>
          Autoplay:
          <input
            type="checkbox"
            checked={heroSettings.autoplay}
            onChange={(e) => setHeroSettings({ ...heroSettings, autoplay: e.target.checked })}
          />
        </label>
        <label>
          Autoplay Speed:
          <input
            type="number"
            value={heroSettings.autoplaySpeed}
            onChange={(e) => setHeroSettings({ ...heroSettings, autoplaySpeed: parseInt(e.target.value) })}
          />
        </label>
        <label>
          Fade:
          <input
            type="checkbox"
            checked={heroSettings.fade}
            onChange={(e) => setHeroSettings({ ...heroSettings, fade: e.target.checked })}
          />
        </label>
        <button onClick={handleUpdateSettings}>Save Settings</button>
      </div>
    </div>
  );
};

export default HeroManagement;
