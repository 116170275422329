import React, { useEffect, useState } from 'react';
import axiosInstance from '../../axiosConfig';

const ProductManagement = () => {
  const [products, setProducts] = useState([]);
  const [newProduct, setNewProduct] = useState({
    name: '',
    image: '',
    description: '',
    category: '',
    url: '',
    inStock: 'Yes',
  });
  const [editingProduct, setEditingProduct] = useState(null);

  useEffect(() => {
    // Fetch products
    axiosInstance.get('/products')
      .then(response => setProducts(response.data))
      .catch(error => console.error('Error fetching products:', error));
  }, []);

  const handleCreateProduct = () => {
    axiosInstance.post('/products', newProduct)
      .then(response => {
        setProducts([...products, response.data]);
        setNewProduct({ name: '', image: '', description: '', category: '', url: '', inStock: 'Yes' });
        alert('Product created successfully');
      })
      .catch(error => console.error('Error creating product:', error));
  };

  const handleUpdateProduct = (productId) => {
    axiosInstance.put(`/products/${productId}`, editingProduct)
      .then(response => {
        const updatedProducts = products.map(product =>
          product.id === productId ? response.data : product
        );
        setProducts(updatedProducts);
        setEditingProduct(null);
        alert('Product updated successfully');
      })
      .catch(error => console.error('Error updating product:', error));
  };

  const handleDeleteProduct = (productId) => {
    axiosInstance.delete(`/products/${productId}`)
      .then(() => {
        setProducts(products.filter(product => product.id !== productId));
        alert('Product deleted successfully');
      })
      .catch(error => console.error('Error deleting product:', error));
  };

  return (
    <div className="product-management">
      <h1>Product Management</h1>

      {/* Product Creation */}
      <div className="add-product">
        <h2>Add New Product</h2>
        <input
          type="text"
          placeholder="Name"
          value={newProduct.name}
          onChange={(e) => setNewProduct({ ...newProduct, name: e.target.value })}
        />
        <input
          type="text"
          placeholder="Image URL"
          value={newProduct.image}
          onChange={(e) => setNewProduct({ ...newProduct, image: e.target.value })}
        />
        <input
          type="text"
          placeholder="Description"
          value={newProduct.description}
          onChange={(e) => setNewProduct({ ...newProduct, description: e.target.value })}
        />
        <input
          type="text"
          placeholder="Category"
          value={newProduct.category}
          onChange={(e) => setNewProduct({ ...newProduct, category: e.target.value })}
        />
        <input
          type="text"
          placeholder="URL"
          value={newProduct.url}
          onChange={(e) => setNewProduct({ ...newProduct, url: e.target.value })}
        />
        <select
          value={newProduct.inStock}
          onChange={(e) => setNewProduct({ ...newProduct, inStock: e.target.value })}
        >
          <option value="Yes">In Stock</option>
          <option value="No">Out of Stock</option>
        </select>
        <button onClick={handleCreateProduct}>Add Product</button>
      </div>

      {/* Product List */}
      <div className="products-list">
        <h2>Current Products</h2>
        <ul>
          {products.map(product => (
            <li key={product.id}>
              <p><strong>Name:</strong> {product.name}</p>
              <p><strong>Image:</strong> <img src={product.image} alt={product.name} /></p>
              <p><strong>Description:</strong> {product.description}</p>
              <p><strong>Category:</strong> {product.category}</p>
              <p><strong>URL:</strong> <a href={product.url} target="_blank" rel="noopener noreferrer">{product.url}</a></p>
              <p><strong>In Stock:</strong> {product.inStock}</p>
              <button onClick={() => setEditingProduct(product)}>Edit</button>
              <button onClick={() => handleDeleteProduct(product.id)}>Delete</button>
              {editingProduct && editingProduct.id === product.id && (
                <div className="edit-product">
                  <input
                    type="text"
                    value={editingProduct.name}
                    onChange={(e) => setEditingProduct({ ...editingProduct, name: e.target.value })}
                  />
                  <input
                    type="text"
                    value={editingProduct.image}
                    onChange={(e) => setEditingProduct({ ...editingProduct, image: e.target.value })}
                  />
                  <input
                    type="text"
                    value={editingProduct.description}
                    onChange={(e) => setEditingProduct({ ...editingProduct, description: e.target.value })}
                  />
                  <input
                    type="text"
                    value={editingProduct.category}
                    onChange={(e) => setEditingProduct({ ...editingProduct, category: e.target.value })}
                  />
                  <input
                    type="text"
                    value={editingProduct.url}
                    onChange={(e) => setEditingProduct({ ...editingProduct, url: e.target.value })}
                  />
                  <select
                    value={editingProduct.inStock}
                    onChange={(e) => setEditingProduct({ ...editingProduct, inStock: e.target.value })}
                  >
                    <option value="Yes">In Stock</option>
                    <option value="No">Out of Stock</option>
                  </select>
                  <button onClick={() => handleUpdateProduct(product.id)}>Save Changes</button>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ProductManagement;