import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Hero = () => {
    const settings = {
        dots: false, // Hide the navigation dots
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        fade: true,
    };

    return (
        <section className="bg-white text-white">
            <Slider {...settings} className="w-full min-h-[40vh] md:min-h-[400px]">
                {[
                    {
                        image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRFOIQE_xEyr1fB_10aYWE_52d0VApXHbfExxFyh_yXTPRtmhN3-eIo5HEalGzubyeA1G0&usqp=CAU",
                        title: "Produtos Royal Horse"
                    },
                    {
                        image: "https://www.admanimalnutrition.com/webcenter/content/conn/WCC1/uuid/dDocName%3aWCTRPRD_003874",
                        title: "Produtos e serviços de qualidade"
                    },
                    {
                        image: "https://www.falamart.com.br/wp-content/uploads/2020/02/veterin%C3%A1ria-gado-loja-agropecu%C3%A1ria-Capa.jpg",
                        title: "AgroVeterinaria Estrela, Aqui seu dinheiro vale mais!"
                    },
                    {
                        image: "https://maisagro.syngenta.com.br/wp-content/uploads/2023/10/IMAGEM_02_CAPA_6-efeitos-fisiologicos-beneficos-de-estrobilurinas-para-milho.jpg",
                        title: "Produtos e serviços de qualidade"
                    },
                    {
                        image: "https://www.universodasaudeanimal.com.br/wp-content/uploads/sites/57/2022/06/Galinha-se-alimentando-garantindo-o-bem-estar-de-aves.jpg",
                        title: "Bem-Estar Animal"
                    },
                    {
                        image: "https://static.poder360.com.br/2022/11/porcos-pixabay-848x477.jpg",
                        title: "Nutrição Suína"
                    },
                    {
                        image: "https://alinutri.ind.br/wp-content/uploads/2024/03/Banner_topo_02.png",
                        title: "AliNutri "
                    },
                    {
                        image: "https://alinutri.ind.br/wp-content/uploads/2023/09/RayCat_Banner_marcas_V4.webp",
                        title: "O Melhor Para Seu Gato!"
                    },
                    {
                        image: "https://www.sementesfeltrin.com.br/UPLarquivos/minis/banners_22_20221216091630.jpg",
                        title: "Semetes que nascem de verdade!"
                    },
                    {
                        image: "https://biofarm.com.br/wp-content/uploads/2024/06/banner-vaquinha-1236.jpg",
                        title: "BIOFARM"
                    },
                    {
                        image: "https://sementesdaterra.com.br/wp-content/uploads/2023/10/logo_sementes_da_terra.webp",
                        title: "Plantou Cresceu."
                    },
                    {
                        image: "https://www.itacorda.com.br/wp-content/uploads/2024/07/ref_a_Itacorda_Banner_Site_2560x1355px_ECO.jpg",
                        title: "itacorda"
                    }
                ].map((slide, index) => (
                    <div key={index} className="relative h-full">
                        <div 
                            className="relative min-h-[40vh] md:min-h-[400px] bg-center bg-no-repeat flex items-center justify-center" 
                            style={{ 
                                backgroundImage: `url('${slide.image}')`, 
                                backgroundSize: 'contain',  // Make the image fit the container
                                backgroundPosition: 'center center' // Center the image
                            }}
                        >
                            <h2 className="hidden md:block text-xl sm:text-2xl md:text-3xl lg:text-5xl bg-opacity-100 bg-black p-2 md:p-4 rounded">
                                {slide.title}
                            </h2>
                        </div>
                        <div className="hidden md:block absolute bottom-4 left-4 text-lg md:text-3xl bg-opacity-50 bg-black p-1 md:p-2 rounded">
                            AgroVeterinaria Estrela, Aqui seu dinheiro vale mais!
                        </div>
                    </div>
                ))}
            </Slider>
        </section>
    );
};

export default Hero;
