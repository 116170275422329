import React, { useState } from 'react';
import Select from 'react-select';
import { products } from './productsData';
import { format } from 'date-fns';

const Produto = ({ wishlistItems }) => {
  const [termoBusca, setTermoBusca] = useState('');
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [produtosPorPagina, setProdutosPorPagina] = useState(28);
  const [categoriasSelecionadas, setCategoriasSelecionadas] = useState([]);
  const [listaDesejos, setListaDesejos] = useState(wishlistItems || []);
  const [mostrarListaDesejos, setMostrarListaDesejos] = useState(true);
  
  const formatDate = () => {
    return format(new Date(), "dd/MM/yyyy 'às' HH:mm");
  };
  
  const handleSendWhatsapp = () => {
    // Check if listaDesejos is not empty before sending the message
    if (!listaDesejos || listaDesejos.length === 0) {
      alert('A lista de desejos está vazia.');
      return;
    }
  
    const message = `
  *Tenho interesse nos seguintes produtos da minha lista de desejos. Poderia, por favor, me fornecer um orçamento?*\n
  ${listaDesejos
      .map(
        (item, index) => `${index + 1}. **${item.name}**\n   Quantidade: ${item.quantity}`
      )
      .join('\n\n')}
  
  **Data:** ${formatDate()}
  
  *Aguardo seu retorno.*
    `;
  
    const encodedMessage = encodeURIComponent(message);
    const whatsappURL = `https://wa.me/5533984098457?text=${encodedMessage}`;
    window.open(whatsappURL, '_blank');
  };
  
  // Obter categorias únicas dos dados dos produtos
  const categorias = [
    { value: 'Mostrar Todos', label: 'Mostrar Todos' },
    ...Array.from(new Set(products.map((produto) => produto.category))).map(
      (categoria) => ({ value: categoria, label: categoria })
    ),
  ];

  const handleCategoriaChange = (opcoesSelecionadas) => {
    const valoresSelecionados = opcoesSelecionadas
      ? opcoesSelecionadas.map((opcao) => opcao.value)
      : [];
    if (valoresSelecionados.includes('Mostrar Todos')) {
      setCategoriasSelecionadas([]);
    } else {
      setCategoriasSelecionadas(valoresSelecionados);
    }
  };

  const alternarListaDesejos = (produto) => {
    setListaDesejos((prev) => {
      const produtoExistente = prev.find((item) => item.name === produto.name);
      if (produtoExistente) {
        return prev.map((item) =>
          item.name === produto.name
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      }
      return [...prev, { ...produto, quantity: 1 }];
    });
  };

  const atualizarListaDesejos = (produto, acao) => {
    setListaDesejos((prev) => {
      const produtoExistente = prev.find((item) => item.name === produto.name);
      if (produtoExistente) {
        if (acao === 'removerUm') {
          return produtoExistente.quantity > 1
            ? prev.map((item) =>
                item.name === produto.name
                  ? { ...item, quantity: item.quantity - 1 }
                  : item
              )
            : prev.filter((item) => item.name !== produto.name);
        }
        if (acao === 'removerTodos') {
          return prev.filter((item) => item.name !== produto.name);
        }
        if (acao === 'adicionarMais') {
          return prev.map((item) =>
            item.name === produto.name
              ? { ...item, quantity: item.quantity + 1 }
              : item
          );
        }
      }
      return prev;
    });
  };

  // Produtos filtrados com base no termo de busca e nas categorias selecionadas
  const produtosFiltrados = products
    .filter(
      (produto) =>
        produto.name.toLowerCase().includes(termoBusca.toLowerCase()) ||
        produto.description.toLowerCase().includes(termoBusca.toLowerCase()) ||
        produto.category.toLowerCase().includes(termoBusca.toLowerCase())
        
    )
    .filter((produto) =>
      categoriasSelecionadas.length > 0
        ? categoriasSelecionadas.includes(produto.category)
        : true
    );

  // Lógica de paginação
  const indiceUltimoProduto = paginaAtual * produtosPorPagina;
  const indicePrimeiroProduto = indiceUltimoProduto - produtosPorPagina;
  const produtosAtuais = produtosFiltrados.slice(
    indicePrimeiroProduto,
    indiceUltimoProduto
  );

  const paginar = (numeroPagina) => setPaginaAtual(numeroPagina);

  return (
    <div className="container mx-auto p-4">
      {/* Paginação Superior e Total de Produtos */}
<div className="flex flex-col sm:flex-row justify-between items-center mb-4">
  <div className="flex space-x-1 overflow-x-auto">
    <button
      onClick={() => paginar(paginaAtual - 1)}
      disabled={paginaAtual === 1}
      className="px-2 py-1 bg-blue-500 text-white rounded"
    >
      Anterior
    </button>
    {Array.from({ length: Math.ceil(produtosFiltrados.length / produtosPorPagina) }, (_, numero) => (
      <button
        key={numero + 1}
        onClick={() => paginar(numero + 1)}
        className={`px-2 py-1 ${
          paginaAtual === numero + 1 ? 'bg-blue-700' : 'bg-blue-500'
        } text-white rounded`}
      >
        {numero + 1}
      </button>
    ))}
    <button
      onClick={() => paginar(paginaAtual + 1)}
      disabled={paginaAtual === Math.ceil(produtosFiltrados.length / produtosPorPagina)}
      className="px-2 py-1 bg-blue-500 text-white rounded"
    >
      Próximo
    </button>
  </div>
  <p className="mt-2 sm:mt-0 font-bold">
    Total de Produtos: <span className="bg-green-500 text-white px-2 py-1 rounded">{produtosFiltrados.length}</span>
  </p>
</div>

      {/* Opções de Produtos por Página */}
      <div className="mb-4">
        <label className="block mb-2">Produtos por Página:</label>
        <select
          onChange={(e) => {
            setProdutosPorPagina(parseInt(e.target.value));
            setPaginaAtual(1); // Reiniciar para a primeira página ao alterar os produtos por página
          }}
          value={produtosPorPagina}
          className="w-full p-2 border border-blue-500 rounded cursor-pointer"
          style={{
            transition: 'all 0.3s ease-in-out',
            backgroundColor: 'blue',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            color: 'white',
          }}
        >
          <option value={28}>28 por página</option>
          <option value={52}>52 por página</option>
          <option value={80}>80 por página</option>
          <option value={produtosFiltrados.length}>Todos</option>
        </select>
      </div>

      {/* Filtro por Categorias com Multiseleção Animada */}
      <div className="mb-4">
        <label className="block mb-2">Filtrar por Categorias:</label>
        <Select
          isMulti
          options={categorias}
          onChange={handleCategoriaChange}
          className="basic-multi-select"
          classNamePrefix="select"
          styles={{
            control: (base) => ({
              ...base,
              borderColor: 'blue',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            }),
          }}
        />
      </div>

      {/* Barra de Pesquisa */}
      <input
        type="text"
        placeholder="Buscar produtos..."
        value={termoBusca}
        onChange={(e) => setTermoBusca(e.target.value)}
        className="mb-4 p-2 w-full border border-blue-500 rounded"
      />

      {/* Grade de Produtos */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        {produtosAtuais.map((produto, index) => (
          <div key={index} className="border rounded-lg p-4 shadow-lg relative">
            <img
              src={produto.image}
              alt={produto.name}
              className="w-full h-72 object-cover mb-4"
              style={{ objectFit: 'cover', width: '221px', height: '300px' }}
            />
            <div
              className={`absolute top-2 left-2 px-2 py-1 rounded text-white ${
                produto.inStock === 'Em Estoque' ? 'bg-green-500' : 'bg-blue-500'
              }`}
            >
              {produto.inStock}
            </div>
            <h2 className="text-xl font-semibold mb-2">{produto.name}</h2>
            <p className="text-gray-600 mb-2">{produto.description}</p>
            <p className="text-yellow-700 mb-4">{produto.inStock}</p>
            <button
              onClick={() => alternarListaDesejos(produto)}
              className={`px-4 py-2 rounded ${
                listaDesejos.some((item) => item.name === produto.name)
                  ? 'bg-green-500'
                  : 'bg-blue-500'
              } text-white`}
            >
              {listaDesejos.some((item) => item.name === produto.name)
                ? 'Adicionado à Lista de Desejos'
                : 'Adicionar à Lista de Desejos'}
            </button>
          </div>
        ))}
      </div>
<br/>
      {/* Paginação Inferior */}
<div className="flex flex-col sm:flex-row justify-between items-center mb-4">
  <div className="flex space-x-1 overflow-x-auto">
    <button
      onClick={() => paginar(paginaAtual - 1)}
      disabled={paginaAtual === 1}
      className="px-2 py-1 bg-blue-500 text-white rounded"
    >
      Anterior
    </button>
    {Array.from({ length: Math.ceil(produtosFiltrados.length / produtosPorPagina) }, (_, numero) => (
      <button
        key={numero + 1}
        onClick={() => paginar(numero + 1)}
        className={`px-2 py-1 ${
          paginaAtual === numero + 1 ? 'bg-blue-700' : 'bg-blue-500'
        } text-white rounded`}
      >
        {numero + 1}
      </button>
    ))}
    <button
      onClick={() => paginar(paginaAtual + 1)}
      disabled={paginaAtual === Math.ceil(produtosFiltrados.length / produtosPorPagina)}
      className="px-2 py-1 bg-blue-500 text-white rounded"
    >
      Próximo
    </button>
  </div>
  <p className="mt-2 sm:mt-0 font-bold">
    Total de Produtos: <span className="bg-green-500 text-white px-2 py-1 rounded">{produtosFiltrados.length}</span>
  </p>
</div>

{mostrarListaDesejos && listaDesejos.length > 0 && (
        <div
          className="fixed bottom-4 right-4 bg-white p-4 rounded-lg shadow-lg w-64"
          style={{ zIndex: 1000, width: '320px', maxHeight: '480px', overflowY: 'auto' }}
        >
          <div className="flex justify-between items-center mb-2">
            <h3 className="font-semibold">Lista de Desejos</h3>
            <button
              onClick={() => setMostrarListaDesejos(false)}
              className="text-blue-500"
            >
              Fechar
            </button>
          </div>

          {/* The New "Pedir Orçamento" Button */}
          <div>
            <button
              onClick={handleSendWhatsapp}
              className="px-4 py-2 bg-green-500 text-white rounded"
            >
              Pedir Orçamento
            </button>
          </div>
          <br />

          <ul>
            {listaDesejos.map((item, index) => (
              <li
                key={index}
                className={`p-2 border-b ${index % 2 === 0 ? 'bg-blue-100' : 'bg-white'}`}
                style={{
                  color:
                    item.inStock === 'Indisponível'
                      ? 'lightcoral'
                      : 'inherit',
                }}
              >
                <div className="flex justify-between items-center">
                  <span>{item.name}</span>
                  <span>
                    {item.quantity}x{' '}
                    <p>{item.inStock}</p>
                    <button
                      onClick={() => atualizarListaDesejos(item, 'adicionarMais')}
                      className="bg-green-500 text-white px-2 rounded"
                    >
                      +
                    </button>
                    <button
                      onClick={() => atualizarListaDesejos(item, 'removerUm')}
                      className="bg-blue-500 text-white px-2 rounded ml-2"
                    >
                      -
                    </button>
                  </span>
                </div>
                <button
                  onClick={() => atualizarListaDesejos(item, 'removerTodos')}
                  className="bg-blue-500 text-white px-4 py-1 rounded mt-2 w-full"
                >
                  Remover Todos
                </button>
              </li>
            ))}
          </ul>
          <button
            onClick={() => setListaDesejos([])}
            className="w-full mt-2 px-4 py-2 bg-blue-500 text-white rounded"
          >
            Limpar Tudo
          </button>
        </div>
      )}

      {!mostrarListaDesejos && listaDesejos.length > 0 && (
        <div
          className="fixed bottom-4 right-4 bg-blue-700 p-2 rounded-lg shadow-lg w-32"
          style={{ zIndex: 1000 }}
        >
          <button
            onClick={() => setMostrarListaDesejos(true)}
            className="text-white w-full"
          >
            Mostrar Lista de Desejos
          </button>
        </div>
      )}
    </div>
  );
};

export default Produto;
