import React, { useEffect, useState } from 'react';
import axiosInstance from '../../axiosConfig';

const DashboardHome = () => {
  const [totalProducts, setTotalProducts] = useState(0);
  const [totalHeroSlides, setTotalHeroSlides] = useState(0);
  const [adminProfile, setAdminProfile] = useState({});
  const [admins, setAdmins] = useState([]);
  const [newAdmin, setNewAdmin] = useState({
    username: '',
    email: '',
    whatsapp: '',
    password: '',
  });

  useEffect(() => {
    // Fetch total products
    axiosInstance.get('/products')
      .then(response => setTotalProducts(response.data.length))
      .catch(error => console.error('Error fetching products:', error));

    // Fetch total hero slides
    axiosInstance.get('/hero/slides')
      .then(response => setTotalHeroSlides(response.data.length))
      .catch(error => console.error('Error fetching hero slides:', error));

    // Fetch admin profile
    axiosInstance.get('/admin/profile')
      .then(response => setAdminProfile(response.data))
      .catch(error => console.error('Error fetching admin profile:', error));

    // Fetch list of admins
    axiosInstance.get('/admin/list')
      .then(response => setAdmins(response.data))
      .catch(error => console.error('Error fetching admins:', error));
  }, []);

  const handleAddAdmin = () => {
    axiosInstance.post('/admin/create', newAdmin)
      .then(response => {
        alert('Admin added successfully');
        setAdmins([...admins, response.data]);
      })
      .catch(error => console.error('Error adding admin:', error));
  };

  const handleUpdateAdmin = (adminId, updatedData) => {
    axiosInstance.put(`/admin/update/${adminId}`, updatedData)
      .then(response => {
        alert('Admin updated successfully');
        const updatedAdmins = admins.map(admin =>
          admin.id === adminId ? response.data : admin
        );
        setAdmins(updatedAdmins);
      })
      .catch(error => console.error('Error updating admin:', error));
  };

  return (
    <div className="dashboard-home">
      <h1>Admin Dashboard</h1>

      <div className="totals">
        <div className="total-item">
          <h2>Total Products</h2>
          <p>{totalProducts}</p>
        </div>
        <div className="total-item">
          <h2>Total Hero Slides</h2>
          <p>{totalHeroSlides}</p>
        </div>
      </div>

      <div className="profile-info">
        <h2>Admin Profile</h2>
        <p><strong>Username:</strong> {adminProfile.username}</p>
        <p><strong>Email:</strong> {adminProfile.email}</p>
        <p><strong>WhatsApp:</strong> {adminProfile.whatsapp}</p>
        <button onClick={() => handleUpdateAdmin(adminProfile.id, adminProfile)}>Update Profile</button>
      </div>

      <div className="admin-management">
        <h2>Manage Admins</h2>
        <input
          type="text"
          placeholder="Username"
          value={newAdmin.username}
          onChange={(e) => setNewAdmin({ ...newAdmin, username: e.target.value })}
        />
        <input
          type="text"
          placeholder="Email"
          value={newAdmin.email}
          onChange={(e) => setNewAdmin({ ...newAdmin, email: e.target.value })}
        />
        <input
          type="text"
          placeholder="WhatsApp"
          value={newAdmin.whatsapp}
          onChange={(e) => setNewAdmin({ ...newAdmin, whatsapp: e.target.value })}
        />
        <input
          type="password"
          placeholder="Password"
          value={newAdmin.password}
          onChange={(e) => setNewAdmin({ ...newAdmin, password: e.target.value })}
        />
        <button onClick={handleAddAdmin}>Add New Admin</button>
        <ul>
          {admins.map(admin => (
            <li key={admin.id}>
              <p><strong>{admin.username}</strong> - {admin.email}</p>
              <button onClick={() => handleUpdateAdmin(admin.id, admin)}>Update</button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DashboardHome;
